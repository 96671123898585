import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { Fade } from "react-reveal"

const Banner: React.FC<any> = ({ title = "", image }) => {
    const language = useSelector((state: RootState) => state.inner.language)
    const [first_line, setFirstLine] = useState('')
    const [second_line, setSecondLine] = useState('')

    useEffect(() => {
        if (title) {
            const tmp_arr = title.split(" ")

            if (tmp_arr.length === 1) {
                setFirstLine(title)
            } else if (tmp_arr.length > 1) {
                if (tmp_arr[0].length > 7) {
                    const second_line_arr = tmp_arr.filter((_, index) => index > 0)
                    setFirstLine(tmp_arr[0])
                    setSecondLine(second_line_arr.join(' '))
                } else {
                    if (tmp_arr[0].length + tmp_arr[1].length < 8) {
                        const second_line_arr = tmp_arr.filter((_, index) => index > 1)
                        setFirstLine(tmp_arr[0] + ' ' + tmp_arr[1])
                        setSecondLine(second_line_arr.join(' '))
                    } else {
                        const second_line_arr = tmp_arr.filter((_, index) => index > 0)
                        setFirstLine(tmp_arr[0])
                        setSecondLine(second_line_arr.join(' '))
                    }
                }
            }
        }
    }, [title])

    const data = useStaticQuery(graphql`
    query {
      customAdminContactsAdminContactsResourceEn {
        fb
        ig
        tw
      }
      customAdminContactsAdminContactsResourceIt {
        fb
        ig
        tw
      }
    }
  `)

    return (
        <div className="c-banner">
            <div>
                <ul>
                    <li className="rellax" data-rellax-speed="2">
                        <a href={"//" + data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].fb}><img src={require('../assets/img/facebook.svg')} alt="facebook" /></a>
                    </li>
                    <li className="rellax" data-rellax-speed="2">
                        <a href={"//" + data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].fb}><img src={require('../assets/img/instagram.svg')} alt="instagram" /></a>
                    </li>
                    <li className="rellax" data-rellax-speed="2">
                        <a href={"//" + data['customAdminContactsAdminContactsResource' + (language === 'IT' ? 'It' : 'En')].fb}><img src={require('../assets/img/twitter.svg')} alt="twitter" /></a>
                    </li>
                </ul>
            </div>
            <div className="words">

                <h1 className="rellax" data-rellax-speed="-2" data-rellax-xs-speed="-0.5" data-rellax-mobile-speed="-0.5" data-rellax-tablet-speed="-1">
                    <Fade left>
                        <span>{first_line}</span>
                    </Fade>
                    <Fade left>
                        {second_line && <span>{second_line}</span>}
                    </Fade>
                </h1>
            </div>
            <div className="slide-in rellax" data-rellax-speed="2" data-rellax-xs-speed="0.5" data-rellax-mobile-speed="0.5" data-rellax-tablet-speed="1">
                {image && <img src={image.url} alt={image.alt} />}
            </div>
        </div>
    )
}

export default Banner